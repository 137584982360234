import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tippy";

export default props => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={props.link}>
      <Tooltip
        title={props.message}
        position="top"
        arrow={true}
        trigger="mouseenter"
        distance={50}
      >
        <FontAwesomeIcon className="icon" icon={props.icon} size="3x" />
      </Tooltip>
    </a>
  );
};
