import React, { Component } from "react";
import Me from "./Me.jpg";
import "./App.css";
import "react-tippy/dist/tippy.css";
import Icon from "./Icon";
import {
  faGithub,
  faMedium,
  faYoutube,
  faLinkedin,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="app-container">
          <img className="avatar" src={Me} alt="" />
          <h1 className="name">MICHAEL NGUYEN</h1>
          <div className="titles">
            <h4>Software Developer</h4>
            <h4>Machine Learning Engineer</h4>
            <h4>Writer & YouTuber</h4>
            <h4>Just a person with big plans</h4>
          </div>
          <h6>Follow me for stories and content on life, software, and AI</h6>
          <div>
            <Icon
              link="https://github.com/LearnedVector"
              icon={faGithub}
              message="Where I build cool stuff"
            />
            <Icon
              link="https://medium.com/@learnedvector"
              icon={faMedium}
              message="Where I make complex things simple in writing"
            />
            <Icon
              link="https://www.youtube.com/channel/UCYpBgT4riB-VpsBBBQkblqQ"
              icon={faYoutube}
              message="Where I make complex things simple in video"
            />
          </div>
          <div>
            <Icon
              link="https://twitter.com/LearnedVector"
              icon={faTwitter}
              message="Where I share knowledge... and read twitter drama"
            />
            <Icon
              link="https://www.linkedin.com/in/michaelpnguyen/"
              icon={faLinkedin}
              message="Where I share my professional journey"
            />
            <Icon
              link="https://www.instagram.com/learnedvector/"
              icon={faInstagram}
              message="Where I share my personal journey"
            />
          </div>
        </div>
        <div className="footer">
          <h6><a className="email" href="mailto:learnedvector@gmail.com">email</a></h6>
        </div>
      </div>
    );
  }
}

export default App;
